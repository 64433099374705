import React from 'react'

const Chat: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M54.25 3.75C54.25 2.64543 53.3546 1.75 52.25 1.75H26.5C25.3954 1.75 24.5 2.64543 24.5 3.75V22.0888C24.5 23.7658 26.4399 24.6981 27.7494 23.6505L32.7022 19.6883C33.0568 19.4046 33.4974 19.25 33.9516 19.25H52.25C53.3546 19.25 54.25 18.3546 54.25 17.25V3.75Z"
        fill="white"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.75 38.5C46.6495 38.5 49 36.1495 49 33.25C49 30.3505 46.6495 28 43.75 28C40.8505 28 38.5 30.3505 38.5 33.25C38.5 36.1495 40.8505 38.5 43.75 38.5Z"
        fill="#8CF9A4"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.25 38.5C15.1495 38.5 17.5 36.1495 17.5 33.25C17.5 30.3505 15.1495 28 12.25 28C9.35051 28 7 30.3505 7 33.25C7 36.1495 9.35051 38.5 12.25 38.5Z"
        fill="#8CF9A4"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M20.75 54.25C21.8546 54.25 22.75 53.3546 22.75 52.25V49.0306C22.75 47.7881 22.0981 46.6454 21.0289 46.0127C19.327 45.0056 16.3765 43.75 12.25 43.75C8.07013 43.75 5.13888 44.9978 3.4545 46.0031C2.394 46.6366 1.75 47.7759 1.75 49.0114V52.25C1.75 53.3546 2.64543 54.25 3.75 54.25H20.75Z"
        fill="#8CF9A4"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M52.25 54.25C53.3546 54.25 54.25 53.3546 54.25 52.25V49.0306C54.25 47.7881 53.5981 46.6454 52.5289 46.0127C50.827 45.0056 47.8765 43.75 43.75 43.75C39.5701 43.75 36.6389 44.9978 34.9545 46.0031C33.894 46.6366 33.25 47.7759 33.25 49.0114V52.25C33.25 53.3546 34.1454 54.25 35.25 54.25H52.25Z"
        fill="#8CF9A4"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default Chat
